<template>
  <div class="EduHome">
    <EduHeader @setGoToUrl="setGoToUrl" />
    <main class="containerMain">
      <NavInfo @setGoToUrl="setGoToUrl" />

      <div
        v-if="examDetails"
        class="containerMainRight"
        style="padding-left: 60px"
      >
        <div class="NewTopicCenter">
          <div class="exam-instructions">
            <div class="header">
              <h1>考前须知</h1>
              <div class="meta">
                <span
                  >时长：{{ examDetails.duration | secondsToTimeStr }}分钟</span
                >
                <span>总分：{{ examDetails.scores ?? 100 }}分</span>
                <span>总题：{{ examDetails.totalTopic ?? 0 }}题</span>
              </div>
            </div>
            <el-card class="instructions">
              <el-row v-for="item in examSectionVOList" :key="item.id">
                <el-col>
                  <h3>{{ item.title }}</h3>
                  <p>
                    {{ item.description }}
                  </p>
                </el-col>
              </el-row>

              <div class="action">
                <!-- <el-button type="primary" style="background-color: #173d7c; color: #fff">进入</el-button> -->
                <div class="enter" @click="toNewTopic(examId, 'NewTopic')">
                  进入
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import { getExamDetails } from "@/api/examController";
import Topic from "@/components/Question/Topic.vue";

export default {
  name: "NewTopic",
  filters: {
    secondsToTimeStr(_seconds) {
      return Math.ceil((_seconds ?? 0) / 60);
    },
  },
  data() {
    return {
      examId: -1,
      examDetails: null,
      examSectionVOList: [],
    };
  },
  created() {
    const id = this.$route.params && this.$route.params.id;
    this.examId = id;
    this.getExamDetails();
  },
  methods: {
    getExamDetails() {
      getExamDetails(this.examId).then((res) => {
        if (res.code == 20000) {
          this.examDetails = res.data;
          this.examSectionVOList = res.data.examSectionVOList;
          console.log(this.examDetails);
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    setGoToUrl(_url) {
      this.goToUrl = _url;
      if (this.examDetails != null) {
        this.fileShow = true;
        if (_url != "") {
          this.isGoTo = true;
        }
      } else {
        this.goTo();
      }
    },
    toNewTopic(id, path) {
      var query = null;
      if (this.examItem != null) {
        query = { method: this.examItem.method };
      }
      this.$router.push({
        path: "/" + path + "/" + id,
        query: query,
      });
    },
  },
  components: {
    EduHeader,
    NavInfo,
    Topic,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  min-height: 100vh;
  .containerMainRight {
    padding-left: 410px;
    .NewTopicCenter {
      width: 1200px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
    }
  }
}
.exam-instructions {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

.header {
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}

.header .meta {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.instructions {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  box-shadow: none !important;
  border: 1px solid #8b8b8b;
}

.instructions h3 {
  color: #666;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.instructions p {
  line-height: 1.6;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}

.action {
  text-align: center;
  margin-top: 20px;
}

.enter {
  display: inline-block;
  padding: 10px 20px;
  background-color: #173d7c;
  color: #fff;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 140px;
  font-size: 16px;
}

.el-button {
  width: 100px;
  height: 40px;
  font-size: 16px;
}
</style>
