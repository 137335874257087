import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
//import { getProfile } from "@/api/authController";
import { getMemberProfile } from "@/api/memberController";
Vue.use(VueRouter);

const routes = [
  //  首页
  {
    path: "/index",
    name: "Index",
    component: () =>
      import(/* webpackChunkName: "Index" */ "../views/Home/index.vue"),
  },
  // 我的仪表盘
  {
    path: "/home",
    name: "EduHome",
    component: () =>
      import(/* webpackChunkName: "EduHome" */ "../views/EduHome/index.vue"),
  },
  // 同学录
  {
    path: "/AlumniBook",
    name: "AlumniBook",
    component: () =>
      import(
        /* webpackChunkName: "AlumniBook" */ "../views/AlumniBook/index.vue"
      ),
  },
  // 学员列表
  {
    path: "/Trainees",
    name: "Trainees",
    component: () =>
      import(/* webpackChunkName: "Trainees" */ "../views/Trainees/index.vue"),
  },
  // 登录
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login/index.vue"),
  },
  // 注册
  {
    path: "/Register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Register/index.vue"),
  },
  // 重置密码
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/ResetPassword/index.vue"
      ),
  },
  // 索取信息
  {
    path: "/AskFor",
    name: "AskFor",
    component: () =>
      import(/* webpackChunkName: "AskFor" */ "../views/AskFor/index.vue"),
  },
  //我的账户
  {
    path: "/MyAccount",
    name: "MyAccount",
    component: () =>
      import(
        /* webpackChunkName: "MyAccount" */ "../views/MyAccount/index.vue"
      ),
  },
  //我的信息（个人信息）
  {
    path: "/MyMessage",
    name: "MyMessage",
    component: () =>
      import(
        /* webpackChunkName: "MyMessage" */ "../views/MyMessage/index.vue"
      ),
  },
  //导览-首页
  {
    path: "/Teaching",
    name: "Teaching",
    component: () =>
      import(/* webpackChunkName: "Teaching" */ "../views/Teaching/index.vue"),
  },
  //教学大纲
  {
    path: "/outline",
    name: "outline",
    component: () =>
      import(/* webpackChunkName: "outline" */ "../views/outline/index.vue"),
  },
  //试卷
  {
    path: "/TestPaper",
    name: "TestPaper",
    component: () =>
      import(
        /* webpackChunkName: "TestPaper" */ "../views/TestPaper/index.vue"
      ),
  },
  //历年考卷
  {
    path: "/Examination/:id",
    name: "Examination",
    component: () =>
      import(
        /* webpackChunkName: "Examination" */ "../views/Examination/index.vue"
      ),
  },
  //习题类型
  {
    path: "/ExaminationCategory",
    name: "ExaminationCategory",
    component: () =>
      import(
        /* webpackChunkName: "ExaminationCategory" */ "../views/ExaminationCategory/index.vue"
      ),
  },
  //历年真题
  {
    path: "/TrueTopic",
    name: "TrueTopic",
    component: () =>
      import(
        /* webpackChunkName: "TrueTopic" */ "../views/TrueTopic/index.vue"
      ),
  },
  //历年真题(新)
  {
    path: "/TrueTopic1/:id",
    name: "TrueTopic1",
    component: () =>
      import(
        /* webpackChunkName: "TrueTopic1" */ "../views/TrueTopic1/index.vue"
      ),
  },
  //年度真题答题报告
  {
    path: "/TitleReport",
    name: "TitleReport",
    component: () =>
      import(
        /* webpackChunkName: "TitleReport" */ "../views/TitleReport/index.vue"
      ),
  },
  //年度真题答题报告(新)
  {
    path: "/TitleReport1/:id",
    name: "TitleReport1",
    component: () =>
      import(
        /* webpackChunkName: "TitleReport1" */ "../views/TitleReport1/index.vue"
      ),
  },
  //错题库
  {
    path: "/WrongQuestion",
    name: "WrongQuestion",
    component: () =>
      import(
        /* webpackChunkName: "WrongQuestion" */ "../views/WrongQuestion/index.vue"
      ),
  },
  //收藏夹
  {
    path: "/Collection",
    name: "Collection",
    component: () =>
      import(
        /* webpackChunkName: "Collection" */ "../views/Collection/index.vue"
      ),
  },
  //搜索
  {
    path: "/Search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "Search" */ "../views/Search/index.vue"),
  },
  //新教学大纲
  {
    path: "/Subject", ///:id
    name: "Subject",
    component: () =>
      import(/* webpackChunkName: "Subject" */ "../views/Subject/index.vue"),
  },
  //历年真题(新)
  {
    path: "/NewTopic/:id",
    name: "NewTopic",
    component: () =>
      import(/* webpackChunkName: "NewTopic" */ "../views/NewTopic/index.vue"),
  },
  //练习(新)
  {
    path: "/Exercise/:id",
    name: "Exercise",
    component: () =>
      import(/* webpackChunkName: "NewTopic" */ "../views/Exercise/index.vue"),
  },
  //冷呼叫
  {
    path: "/ColdCall",
    name: "ColdCall",
    component: () =>
      import(/* webpackChunkName: "ColdCall" */ "../views/ColdCall/index.vue"),
  },
  //购买页面
  {
    path: "/PurchasePage",
    name: "PurchasePage",
    component: () =>
      import(
        /* webpackChunkName: "ColdCall" */ "../views/PurchasePage/index.vue"
      ),
  },
  {
    path: "/purchaseShow",
    name: "purchaseShow",
    component: () =>
      import(
        /* webpackChunkName: "ColdCall" */ "../views/PurchasePage/purchaseShow.vue"
      ),
  },
  {
    path: "/purchaseMore",
    name: "purchaseMore",
    component: () =>
      import(
        /* webpackChunkName: "ColdCall" */ "../views/PurchasePage/purchaseMore.vue"
      ),
  },
  {
    path: "/purchaseitem",
    name: "purchaseitem",
    component: () =>
      import(
        /* webpackChunkName: "ColdCall" */ "../views/PurchasePage/purchaseitem.vue"
      ),
  },
  {
    path: "/purchaseitemInfo",
    name: "purchaseitemInfo",
    component: () =>
      import(
        /* webpackChunkName: "ColdCall" */ "../views/PurchasePage/purchaseitemInfo.vue"
      ),
  },
  //考前须知
  {
    path: "/examNotice/:id",
    name: "examNotice",
    component: () =>
      import(
        /* webpackChunkName: "TestPaper" */ "../views/ExamNotice/index.vue"
      ),
  },

  //测试页
  {
    path: "/Test",
    name: "Test",
    component: () =>
      import(/* webpackChunkName: "ColdCall" */ "../views/Test/index.vue"),
  },
];
const router = new VueRouter({
  routes,
});
// router.beforeEach(async (to, from, next) => {
//   if (store.state.token) {
//     const { code } = await getProfile();
//     if (code == 40100) {
//       store.commit("del_token");
//       next({ path: "/" });
//       return;
//     }
//     if (to.path === "/" || to.path === "/Register") {
//       next({ path: "/home" });
//     } else {
//       next();
//     }
//   } else {
//     if (
//       to.path === "/" ||
//       to.path === "/Register" ||
//       to.path === "/Teaching" ||
//       to.path === "/ResetPassword"
//     ) {
//       next();
//     } else {
//       next({
//         path: "/",
//         query: { redirect: to.path },
//         replace: true,
//       });
//     }
//   }
// });

router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  const index = "/Login"; ///PurchasePage
  if (store.state.session == "" && !store.state.isLogout) {
    const { code, data } = await getMemberProfile(); //getProfile();
    if (code >= 20000 && code <= 30000) {
      store.commit("set_token", data.id);
      if (data.avatar || data.avatar != null) {
        sessionStorage.setItem("MEMBER_AVATAR", data.avatar);
      } else {
        sessionStorage.setItem("MEMBER_AVATAR", "");
      }
      if (to.path === "/" || to.path === "/Register") {
        next({ path: "/home" });
      } else {
        next();
      }
    } else {
      store.commit("del_token");
      store.state.isLogout = true;
      //next({ path: "/" });
      if (to.path === "/") {
        next({
          path: index,
        });
      } else if (
        to.path === "/Login" ||
        to.path === "/Register" ||
        to.path === "/ResetPassword" ||
        to.path === "/PurchasePage" ||
        to.path === "/purchaseShow" ||
        to.path === "/purchaseMore" ||
        to.path === "/purchaseitem" ||
        to.path === "/purchaseitemInfo"
      ) {
        next();
      } else {
        next({
          path: "/Login",
          query: { redirect: to.path },
          replace: true,
        });
      }
      return;
    }
  } else {
    if (!store.state.isLogout) {
      if (
        to.path === "/" ||
        to.path === "/Register" ||
        to.path === "/ResetPassword"
      ) {
        next({ path: "/home" });
      } else {
        // next({
        //   path: "/",
        //   query: { redirect: to.path },
        //   replace: true,
        // });
        next();
      }
    } else {
      if (to.path === "/") {
        next({
          path: "/home",
        });
      } else if (
        to.path === "/Login" ||
        to.path === "/Register" ||
        to.path === "/ResetPassword" ||
        to.path === "/PurchasePage" ||
        to.path === "/purchaseShow" ||
        to.path === "/purchaseMore" ||
        to.path === "/purchaseitem" ||
        to.path === "/purchaseitemInfo"
      ) {
        next();
      } else {
        next({
          path: "/",
          query: { redirect: to.path },
          replace: true,
        });
      }
    }
  }
});
// router.afterEach((to) => {});
export default router;
